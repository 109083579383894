import AVOTrack from '@lib/avo'
import { reactive } from 'vue'

import monitor from '@/bootstrap/monitor/monitor'
import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import { FeaturePermissionsType } from '@/features/packaging/domain/enums/featurePermissions.enum'
import {
    AddressFeature,
    DefaultFailSafeStrategy,
    TierPricingSchedule
} from '@/features/packaging/domain/interfaces/features.interfaces'
import { defaultFailSafeStrategyByCountry } from '@/features/packaging/utils/failSafeStrategy/defaultFailSafeStrategy'
import store from '@/store'

interface PackagingState {
    featuresInformation: AddressFeature[]
    initError: string
    isInitialized: boolean
    schedulesInformation: TierPricingSchedule[]
}

const packagingState = reactive<PackagingState>({
    featuresInformation: [],
    initError: '',
    isInitialized: false,
    schedulesInformation: []
})

const KNOWN_INIT_ERRORS = {
    loadDynamicModule: 'Error loading packagingService module',
    fetchPackageFeatures: 'Error fetching package features',
    fetchSchedulesInfo: 'Error fetching schedules information'
}

let packagingService: any
const PLANS_AVAILABLE = ['promo', 'starter', 'plus', 'vip']
const FREE_PLAN = 'free'
const PRIVATE_TYPE = 'private'

// eslint-disable-next-line sonarjs/cognitive-complexity
export function usePackaging() {
    const isAdvanceAppointmentUpsellingEnabled = ensureToggleIsEnabled('TierPricingAdvanceAppointmentUpselling', true)
    const isEhrUpsellingEnabled = ensureToggleIsEnabled('TierPricingShowUpselling', true)
    const isPostOfficeUpsellingEnabled = ensureToggleIsEnabled('PostOfficeUsesPackageApp', true)
    const isUsePackageAppFailSafeStrategyEnabled = ensureToggleIsEnabled('UsePackageAppFailSafeStrategy', true)

    async function initService() {
        try {
            if (packagingService) {
                return
            }
            const module = await import(
                /* webpackChunkName: "packaging-service" */ '@/features/packaging/domain/packaging.service'
            )
            packagingService = module.default
        } catch (e: any) {
            packagingState.initError = KNOWN_INIT_ERRORS.loadDynamicModule
            e.message = `${KNOWN_INIT_ERRORS.loadDynamicModule}: ${e.message}`
            monitor.sendException(e)
        }
    }

    function isSecretary() {
        return store.getters[AuthGetterTypes.IsSecretaryOfIndividualDoctor]
    }

    function canUsePackaging(): boolean {
        return store.getters[AuthGetterTypes.IsDoctor] || isSecretary()
    }

    async function fetchPackagingSchedules() {
        try {
            packagingState.schedulesInformation = await packagingService.getTierPricingSchedules()
        } catch {
            packagingState.initError = KNOWN_INIT_ERRORS.fetchSchedulesInfo
        }
    }

    async function fetchPackagingFeatures(userId: number) {
        try {
            packagingState.featuresInformation = await packagingService.getAddressFeaturesInformation(userId)
        } catch {
            packagingState.initError = KNOWN_INIT_ERRORS.fetchPackageFeatures
        }
    }

    async function initializePackaging(): Promise<void> {
        if (!canUsePackaging()) {
            return
        }
        await initService()

        const user = store.getters[AuthGetterTypes.GetAuthUser]

        const userId = isSecretary() ? Number(user.hostDocplannerDoctorId) : Number(user.docplannerDoctorId)

        await Promise.all([fetchPackagingSchedules(), fetchPackagingFeatures(userId)])

        packagingState.isInitialized = true
    }

    function isPackagingInitializedWithoutErrors(): boolean {
        return packagingState.isInitialized && !packagingState.initError
    }

    function getCountry(): string {
        return store.getters[AuthGetterTypes.GetCountryCode].toLowerCase()
    }

    function getDefaultFailSafe(): DefaultFailSafeStrategy | undefined {
        const country = getCountry()
        return defaultFailSafeStrategyByCountry.find(f => f.country === country)
    }

    function getPackageFeatures(): AddressFeature[] {
        return packagingState.featuresInformation
    }

    function getPackageSchedules(): TierPricingSchedule[] {
        return packagingState.schedulesInformation
    }

    function getIsPrivatePremiumUser(): boolean {
        if (!canUsePackaging() || !isPackagingInitializedWithoutErrors()) {
            return true
        }
        return getPackageFeatures().some(({ plans, type }) => {
            return type === PRIVATE_TYPE && !plans.includes(FREE_PLAN)
        })
    }

    function getFeatureFailSafe(featureName: FeaturePermissionsType): string | undefined {
        const failSafe = getDefaultFailSafe()
        return failSafe?.features.find(f => f.includes(featureName))
    }

    function trackFeatureUsage(featureName: string): void {
        AVOTrack.packageFeatureCheck({
            feature: featureName
        })
    }

    function hasFeatureBySchedule(scheduleId: number, featureName: FeaturePermissionsType): boolean {
        if (!canUsePackaging() || !isPackagingInitializedWithoutErrors()) {
            return true
        }

        const features = getPackageFeatures()
        const schedule = getPackageSchedules()

        if (isUsePackageAppFailSafeStrategyEnabled) {
            const feature = getDefaultFailSafe()?.features.find((f: string) => f.includes(featureName))

            if (feature) {
                trackFeatureUsage(featureName)
            }

            return Boolean(feature)
        }

        const scheduleAddress = schedule?.find(s => s.saasScheduleId === scheduleId)?.mpDocplannerDoctorAddressId

        const feature = scheduleAddress
            ? features.find(f => f.addressId === scheduleAddress && f.features.includes(featureName))
            : null

        if (feature) {
            trackFeatureUsage(featureName)
        }

        return Boolean(feature)
    }

    function hasFeatureForAtLeastOneSchedule(featureName: FeaturePermissionsType): boolean {
        if (!canUsePackaging() || !isPackagingInitializedWithoutErrors()) {
            return true
        }

        const features = getPackageFeatures()

        const feature = isUsePackageAppFailSafeStrategyEnabled
            ? getFeatureFailSafe(featureName)
            : features?.find(f => f.features.includes(featureName))

        if (feature) {
            trackFeatureUsage(featureName)
        }

        return Boolean(feature)
    }

    function hasPlan(): boolean {
        if (!canUsePackaging() || !isPackagingInitializedWithoutErrors()) {
            return false
        }

        const features = getPackageFeatures()

        return PLANS_AVAILABLE.some(value => features[0]?.plans.includes(value))
    }

    function addressIdsList(): string[] {
        return getPackageFeatures()
            .filter(address => address.addressId)
            .map(address => address.addressId)
    }

    function findSchedule(): number | null {
        const schedules = getPackageSchedules()
        const addressIds = addressIdsList()

        const schedule = schedules.find(
            s =>
                s.mpDocplannerDoctorAddressId &&
                addressIds.includes(s.mpDocplannerDoctorAddressId) &&
                (s.medicalCenterId === null || s.isFromFakeClinic === true)
        )

        if (schedule) return schedule.saasScheduleId

        return null
    }

    function getScheduleIdFromPatientOwnerId(ownerId: number): number | null {
        const authUserId = store.getters[AuthGetterTypes.GetUserId]

        if (ownerId === authUserId) {
            return findSchedule()
        }

        if (isSecretary()) {
            const hostId = Number(store.getters[AuthGetterTypes.GetAuthUser].hostId)
            if (ownerId === hostId) {
                return findSchedule()
            }
        }

        return getPackageSchedules().find(s => s.medicalCenterId === ownerId)?.saasScheduleId || null
    }

    function getIsFreeDoctor(): boolean {
        if (!canUsePackaging() || !isPackagingInitializedWithoutErrors()) {
            return false
        }
        const addresses = getPackageFeatures()
        return addresses.length ? addresses.every(({ plans }) => plans.includes(FREE_PLAN)) : false
    }

    function isClinicSchedule(ownerId: number): boolean {
        const schedules = getPackageSchedules()
        const schedule = schedules.find(s => s.medicalCenterId === ownerId)

        return schedule !== undefined
    }

    function hasFeatureByPatientOwnerId(ownerId: number, featureName: FeaturePermissionsType): boolean {
        if (!canUsePackaging() || !isPackagingInitializedWithoutErrors()) {
            return true
        }

        if (isUsePackageAppFailSafeStrategyEnabled) {
            return hasFeatureForAtLeastOneSchedule(featureName)
        }
        const scheduleId = getScheduleIdFromPatientOwnerId(ownerId)
        return scheduleId ? hasFeatureBySchedule(scheduleId, featureName) : true
    }

    function hasFeatureStartingWith(prefix: string) {
        if (!canUsePackaging() || !isPackagingInitializedWithoutErrors()) {
            return true
        }
        const features = getPackageFeatures()

        const hasFeature = features.some(f => {
            return f.features.some(featureString => featureString.startsWith(prefix))
        })

        if (hasFeature) {
            trackFeatureUsage(prefix)
        }

        return hasFeature
    }

    function hasAtLeastOneAddressWithPlan(plan: string): boolean {
        if (!canUsePackaging() || !isPackagingInitializedWithoutErrors()) {
            return true
        }

        const features = getPackageFeatures()

        return features.some(f => {
            return f.plans.includes(plan)
        })
    }

    function hasPrivateAddressesWithPlan(plan: string): boolean {
        if (!canUsePackaging() || !isPackagingInitializedWithoutErrors()) {
            return true
        }
        return getPackageFeatures().every(({ plans, type }) => {
            return type === PRIVATE_TYPE && plans.includes(plan)
        })
    }

    return {
        packagingState,
        initializePackaging,
        canUsePackaging,
        isAdvanceAppointmentUpsellingEnabled,
        isEhrUpsellingEnabled,
        isPostOfficeUpsellingEnabled,
        getIsPrivatePremiumUser,
        getIsFreeDoctor,
        hasFeatureBySchedule,
        hasFeatureForAtLeastOneSchedule,
        hasPlan,
        hasFeatureByPatientOwnerId,
        hasFeatureStartingWith,
        hasAtLeastOneAddressWithPlan,
        isClinicSchedule,
        hasPrivateAddressesWithPlan
    }
}
